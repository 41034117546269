import React from 'react'
import { BrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import SACPage from './Pages/SACPage';
import TraficoPage from './Pages/TraficoPage';
import Recollections from './components/Trafico/Recollections';
import Citas from './components/Trafico/Citas';
import RexLayout from './Pages/Layouts/RexLayout';
import GuidesPage from './Pages/GuidesPage';
import HomePage from './Pages/HomePage';
import DetailedCalendar from './components/DetailedCalendar';
import ScreensPage from './Pages/ScreensPage';
import ScannerPage from './Pages/Posada2024/ScannerPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SACPage />} />
        <Route path="/trafico" element={<TraficoPage />} />
        <Route path="/reportes" element={<RexLayout />}>
          <Route index element={<HomePage />} />
          <Route path="recolecciones" element={<Recollections />} />
          <Route path="calendario-recolecciones" element={<DetailedCalendar />} />
          <Route path="citas" element={<Citas />} />
          <Route path='guias' element={<GuidesPage />} />
        </Route>
        <Route path="/pantallas" element={<RexLayout />}>
          <Route index element={<ScreensPage />} />
        </Route>

        <Route path="/posada-2024" element={<ScannerPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App