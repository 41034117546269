import { Box, Button, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import RedexpressLogo from '../../assets/imgs/posada-2024/logo-rex-blanco.png';
import PosadaBG from '../../assets/imgs/posada-2024/posada-fondo-3.webp';
import { Scanner } from '@yudiel/react-qr-scanner';
import Swal from 'sweetalert2';

const ScannerPage = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenRegister, onOpen: onOpenRegister, onClose: onCloseRegister } = useDisclosure();
    const [totalRegistered, setTotalRegistered] = useState();
    const [employee, setEmployee] = useState();
    const [employees, setEmployees] = useState();
    const [employeNum, setEmployeeNum] = useState();

    const handleScanEmployee = ([qr]) => {
        fetch(`${process.env.REACT_APP_API_URL}/get_user/${qr.rawValue}`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.user) {
                    setEmployee(data.user);
                    onOpen();
                } else {
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: `No se ha encontrado ningun colaborador con el QR escaneado.`,
                        confirmButtonText: 'Escanear otro empleado',
                        confirmButtonColor: '#2F82CE'
                    });
                }
            }).catch((err) => {
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    text: `Ha habido un error al escanear el QR, puede estar dañado o no ser el indicado.`,
                    confirmButtonText: 'Escanear otro empleado',
                    confirmButtonColor: '#2F82CE'
                });
            });
    }

    const handleSearchEmployees = async (search) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/get_users?search=${search}`, {
            'headers': {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        });
        const data = await res.json();

        setEmployees(data.users);
    }

    const handleSearchEmployee = (employee_id) => {
        onCloseRegister();
        fetch(`${process.env.REACT_APP_API_URL}/get_user/${employee_id}`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.user) {
                    setEmployee(data.user);
                    onOpen();
                } else {
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: `No se ha encontrado ningun colaborador con el número de empleado ingresado.`,
                        confirmButtonText: 'Buscar otro empleado',
                        confirmButtonColor: '#2F82CE'
                    });
                }
            }).catch((err) => {
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    text: `Ha habido un error al buscar el empleado.`,
                    confirmButtonText: 'Buscar otro empleado',
                    confirmButtonColor: '#2F82CE'
                });
            });
    }

    const handleCloseModal = () => {
        setEmployee(null);
        onClose();
    }

    const handleCloseRegister = () => {
        setEmployees(null);
        setEmployeeNum(null);
        onCloseRegister();
    }

    const handleRegisterUser = async (accompanied) => {
        onClose();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/register_user`, {
            body: JSON.stringify({
                employee_id: employee.employee_id,
                is_accompanied: accompanied
            }),
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                'Content-Type': 'application/json'
            }
        });

        const code = res.status;
        const data = await res.json();

        if(code === 200) {
            Swal.fire({
                title: 'Registro completado',
                icon: 'success',
                text: data.message,
                confirmButtonText: 'Escanear otro empleado',
                confirmButtonColor: '#2F82CE'
            }).then(() => {
                setTotalRegistered(data.total_registered);
                setEmployee(null);
            });
        } else {
            Swal.fire({
                title: 'Registro fallido',
                icon: 'error',
                text: data.message,
                confirmButtonText: 'Escanear otro empleado',
                confirmButtonColor: '#2F82CE'
            }).then(() => {
                setEmployee(null);
            });
        }

    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/get_total_registered_users`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                setTotalRegistered(data.total);
            });
    }, []);
    

    return (
        <>
            <Box backgroundImage={PosadaBG} backgroundSize='cover' backgroundPosition='center' display='flex' flexDir='column' h='100vh' justifyContent='center' alignItems='center'>
                <Image w={400} src={RedexpressLogo} />
                <Heading my={5} color='white'>Posada Navideña 2024</Heading>
                <Box w={300}>
                    <Scanner
                        onScan={handleScanEmployee}
                        allowMultiple
                        scanDelay={1000}
                    />
                </Box>
                <Text fontSize={24} color='white'>Escanea la invitación del colaborador</Text>
                <Button onClick={onOpenRegister} my={3} colorScheme='red'>Registrar manualmente</Button>
                <Text fontSize={30} color='white'><b>Invitados registrados:</b> {totalRegistered}</Text>
            </Box>
            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Registro de empleado</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {
                            (employee)
                                ? (
                                    <>
                                        <Text lineHeight={0} fontSize={16} fontWeight='bold' textAlign='center' my={5}>Datos empleado</Text>
                                        <Text fontSize={16} textAlign='center' my={5}>{employee.employee_id} - {employee.full_name}</Text>
                                        <Text fontSize={20} textAlign='center' my={5}>¿El colaborador viene acompañado?</Text>
                                        <Text fontSize={16} textAlign='center' my={5}>Haga click en alguna opción</Text>
                                        <HStack>
                                            <Box as={Button} onClick={() => handleRegisterUser(0)} borderRadius={5} flex={1} display='flex' justifyContent='center' alignItems='center' bgColor='red.500' h={40}>
                                                <Text color='white' fontWeight='bold'>Sin invitado</Text>
                                            </Box>
                                            <Box as={Button} onClick={() => handleRegisterUser(1)} borderRadius={5} flex={1} display='flex' justifyContent='center' alignItems='center' bgColor='blue.500' h={40}>
                                                <Text color='white' fontWeight='bold'>Con invitado</Text>
                                            </Box>
                                        </HStack>
                                    </>
                                )
                                : <Text fontSize={20} textAlign='center' my={5}>No se ha encontrado ningun colaborador con este QR, intenté de nuevo.</Text>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenRegister} onClose={handleCloseRegister}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Buscar empleado</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Ingresa número de empleado</Text>
                        <Input type="text" onChange={(e) => setEmployeeNum(e.target.value)} />
                        <Button mt={3} w='100%' colorScheme='blue' onClick={() => handleSearchEmployees(employeNum)}>Buscar</Button>
                        <hr />
                        {
                            (employees) && (
                                <Box mt={3}>
                                    <Text>Selecciona un usuario</Text>
                                    <Select onChange={(e) => setEmployeeNum(e.target.value)}>
                                        <option value="">Selecciona un usuario</option>
                                        {
                                            employees.map((employee) => (
                                                <option value={employee.employee_id}>{employee.full_name}</option>
                                            ))
                                        }
                                    </Select>
                                    <Button mt={3} w='100%' colorScheme='blue' onClick={() => handleSearchEmployee(employeNum)}>Registar usuario</Button>
                                </Box>
                            )
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ScannerPage